import React, { createContext, FC, useEffect, useReducer } from "react";

// third-party
// import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from "uuid";

import { osVersion } from "react-device-detect";

// reducer - state management
import accountReducer from "src/stores/accountReducer";
import { ACCOUNT_INITIALIZE, CLEAR_ERRORS, LOGIN, LOGIN_FAILURE, LOGOUT } from "src/stores/actions";

// project imports
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfile } from "src/_mockApis/user-profile/types";
import { isHostWithoutOrgAvailable } from "src/App";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import Loader from "src/components/Loader";
import config from "src/config";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import store from "src/store/store";
import { initialLoginContextProps } from "src/types";
import axios from "src/utils/axios";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { loadApps, loadPermissions } from "src/utils/buildxProviderOperations";

// constant

const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  error: null,
};

export const setSession = (accessToken?: string | null, user?: UserProfile | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("admin-login");
    // Remove all keys ending with "-profileId"
    for (const key in localStorage) {
      if (key.endsWith("-profileId")) {
        localStorage.removeItem(key);
      }
    }
    // delete axios.defaults.headers.common.Authorization;
  }
};

export const registerDeviceToken = async () => {
  const deviceUDID = uuidv4();

  const deviceInfo = {
    appVersion: config.appVersion,
    deviceType: config.deviceType,
    deviceUDID,
    osVersion,
    pushToken: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userId: "",
  };

  let accessToken = localStorage.getItem("accessToken-device");

  if (!accessToken) {
    const res = await axios.post("/device", deviceInfo);
    accessToken = res.data.accessToken;
    if (res?.data?.accessToken) {
      localStorage.setItem("accessToken-device", res.data.accessToken);
    }
    if (res?.data?.creationTime) {
      localStorage.setItem("token_created_at", res.data.creationTime);
    }
    localStorage.setItem("device_uuid", deviceUDID);
  }
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext({
  ...initialState,
  login: (e: string, p: string, r: string) => Promise.resolve(),
  loginSuccess: (e: any) => {},
  loginFailure: (e: any) => {},
  clearErrors: () => {},
  registerDevice: () => Promise.resolve(),
  logout: () => {},
  setUserAuth: () => {},
  isSuperAdmin: () => false,
});

export const JWTProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const currentApp = useBuildxProviderValue("currentApp");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const { appRoutesMap } = useBuildxContext();

  const verifyToken: (st: string) => boolean = accessToken => {
    if (!accessToken) {
      navigate({ pathname: "/login" });
      return false;
    }

    const user = localStorage.getItem("user");
    if (!user) return false;

    const date = new Date().getTime();
    const token_created_at = localStorage.getItem("token_created_at");
    if (!token_created_at) return false;

    const one_year = 365 * 24 * 60 * 60 * 1000;

    return date < Number(token_created_at) + one_year;
  };

  const registerDevice = async () => {
    registerDeviceToken();
    dispatch({
      type: ACCOUNT_INITIALIZE,
      payload: {
        ...state,
        isLoggedIn: false,
        user: null,
      },
    });
  };

  const loginSuccess = (principal: any) => {
    dispatch({
      type: LOGIN,
      payload: {
        ...state,
        user: principal,
      },
    });
  };
  const loginFailure = (message: any) => {
    dispatch({
      type: LOGIN_FAILURE,
      payload: {
        ...state,
        error: message,
      },
    });
  };

  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
      payload: {
        ...state,
        error: null,
      },
    });
  };

  const login = async (email: string, password: string) => {
    try {
      await registerDevice();
      const response = await axios.post(
        "/auth/authenticate",
        {
          email,
          password,
          isLogin: true,
          organizationId: !isHostWithoutOrgAvailable ? undefined : localStorage.getItem("orgId"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken-device"),
          },
        }
      );
      const { accessToken, principal } = response.data;

      if (window.location.pathname == "/admin/login") {
        localStorage.setItem("admin-login", "1");
      } else {
        localStorage.removeItem("admin-login");
      }
      setSession(accessToken, principal);
      await loadPermissions?.();
      loadApps?.(location, navigate, fqdnApp, appRoutesMap, currentApp, true);
      loginSuccess(principal);
    } catch (error: any) {
      enqueueSnackbarRef?.("Username or password is incorrect", {
        variant: "error",
      });
      loginFailure(error.message);
    }
  };

  const logout = () => {
    navigate({ pathname: localStorage.getItem("admin-login") ? "/admin/login" : "/login" });
    setSession(null);
    dispatch({ type: LOGOUT });
    store.dispatch(setProviderState({ isAdministrationMode: false, currentApp: null, appDescriptor: [], isSwitchingMode: false }));
  };

  const setUserAuth = () => {
    dispatch({ type: LOGOUT });
  };
  useEffect(() => {
    const init = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken && verifyToken(accessToken)) {
          const user_profile = localStorage.getItem("user");
          let user: any = null;
          if (!_.isNil(user_profile)) {
            user = JSON.parse(user_profile || "{}");
          }
          setSession(accessToken, user);
          await loadPermissions?.();
          loadApps?.(location, navigate, fqdnApp, appRoutesMap, currentApp);
          dispatch({
            type: ACCOUNT_INITIALIZE,
            payload: {
              ...state,
              isLoggedIn: true,
              user,
            },
          });
        } else {
          if (!_.isEmpty(appRoutesMap.current) && !appRoutesMap.current.hasOwnProperty(window.location.pathname)) {
            localStorage.setItem("targetPathAfterLogin", window.location.pathname);
            navigate({ pathname: "/login" });
          }
          store.dispatch(setProviderState({ loadingApps: false }));
          registerDevice();
        }
      } catch (err) {
        store.dispatch(setProviderState({ loadingApps: false }));
        registerDevice();
      }
    };

    init();
  }, []);

  if (!state.isInitialized) {
    return <Loader />;
  }
  // @ts-ignore
  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        registerDevice,
        loginSuccess,
        loginFailure,
        clearErrors,
        setUserAuth,
        isSuperAdmin: () => !!state?.user?.roles?.find(role => role.superAdminRole) as any,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
